import React, { useEffect, useState } from "react";
import "./UserQuizManualPage.css";
import SidebarUser from "../../components/SidebarUser";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Loader from "../../components/Loader";
import { fetchQuizzes } from "../../actions/quizzesActions";

const UserQuizManualPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  // const quizId = urlParams.get("quizId");

  // const quizzesReducer = useSelector((state) => state.quizzesReducer);
  // const [quizzes, setQuizzes] = useState(quizzesReducer.quizzes);
  // const [quiz, setQuiz] = useState(
  //   quizzes.filter((q) => q._id === quizId)
  // );
  const quizId = urlParams.get("quizId");
  const quizzesReducer = useSelector((state) => state.quizzesReducer);
  const [quizzes, setQuizzes] = useState(quizzesReducer.quizzes);
  const [quiz, setQuiz] = useState([]);
  const [questionSize, setQuestionSize] = useState(0);

  useEffect(() => {
    if (quizId && quizzesReducer.quizzes.length > 0) {
      const filteredQuizzes = quizzesReducer.quizzes.filter(
        (q) => q._id === quizId
      )[0];
      setQuiz(filteredQuizzes);
    }
  }, [quizId, quizzesReducer.quizzes]);
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  const startQuizHandler = (quizTitle, quizId) => {
    navigate(`/questions/?quizId=${quizId}&quizTitle=${quizTitle}`);
  };

  useEffect(() => {
    if (quizzes.length == 0) {
      fetchQuizzes(dispatch, token).then((data) => {
        const temp = data.payload;
        setQuizzes(temp);
        setQuiz(temp.filter((q) => q._id == quizId)[0]);
      });
    }
  }, []);

  useEffect(() => {
    if (quiz && quiz.questions) {
      // console.log("quiz", quiz.questions.length);
      setQuestionSize(quiz.questions.length);
      console.log("quiz", questionSize);
    }
  }, [quiz]);


  // useEffect(() => {
  //   if (!localStorage.getItem("jwtToken")) navigate("/");
  // }, []);

  return (
    // <div className="quizManualPage__container">
    //   {/* <div className="quizManualPage__sidebar">
    //     <SidebarUser />
    //   </div> */}
    //   {quiz ? (
    //     <div className="quizManualPage__content">
    //       <div className="quizManualPage__content--section">
    //         <h5>Read the instruction of this page carefully</h5>
    //         <p style={{ color: "grey" }}>One more step to go</p>
    //       </div>

    //       <div className="quizManualPage__content--section">
    //         <h3>{quiz.title}</h3>
    //         <p>{quiz.description}</p>
    //       </div>

    //       <hr />

    //       <div>
    //         <h3>Important Instructions</h3>
    //         <ul>
    //           <li>This quiz is only for practice purpose.</li>
    //           <li>
    //             You have to submit quiz within <strong>{quiz.totalQuestions * 2}</strong> Minutes.
    //           </li>
    //           <li>You can attempt the quiz any number of time.</li>
    //           <li>
    //             There are <strong>{quiz.totalQuestions} questions</strong> in
    //             this quiz.
    //           </li>
    //           <li>This quiz is only for practice purpose.</li>
    //           <li>
    //             Total Marks for this quiz is <strong>{quiz.totalQuestions * 2}.</strong>
    //           </li>
    //           <li>All question is of MCQ type.</li>
    //         </ul>
    //       </div>

    //       <hr />

    //       <div>
    //         <h3>Attempting Quiz</h3>
    //         <ul>
    //           <li>
    //             Click <strong>Start Quiz</strong> button to start the quiz.
    //           </li>
    //           <li>
    //             The timer will start the moment, you will click on the Start
    //             Quiz button.
    //           </li>
    //           <li>
    //             You can not resume this quiz if interrupted due to any reason.
    //           </li>
    //           <li>
    //             Click on <strong>Submit Quiz</strong> button on completion of
    //             the quiz.
    //           </li>
    //           <li>
    //             Result of the test is generated automatically in PDF format.
    //           </li>
    //         </ul>
    //       </div>

    //       <Button
    //         className="quizManualPage__content--button"
    //         onClick={() => startQuizHandler(quiz.title, quiz._id)}
    //         style={{
    //           border: "1px solid grey",
    //           margin: "2px 8px",
    //         }}
    //         variant="primary"
    //       >{`Start Quiz`}</Button>
    //     </div>
    //   ) : (
    //     <Loader />
    //   )}
    // </div>
    <div className="flex flex-col md:flex-row mx-4 md:mx-auto my-8 max-w-4xl">
      {/* Sidebar */}
      {/* <div className="md:w-1/4">
        <SidebarUser />
      </div> */}

      {/* Content */}
      {quiz ? (
        <div className="w-screen">
          <div className="bg-white mx-auto p-4 rounded shadow-md">
            <div className="mb-4">
              <h5 className="text-2xl text-red-600 font-bold">
                Read the instructions carefully
              </h5>
              <p className="font-semibold">One more step to go</p>
            </div>

            <div className="mb-4">
              <h3 className="text-4xl font-extrabold text-blue-500">
                {quiz.title}
              </h3>
              <p className="font-semibold">{quiz.description}</p>
            </div>

            <hr className="my-4 border-t border-gray-300" />

            <div className="mb-4">
              <h3 className="text-xl font-semibold">Important Instructions</h3>
              <ul className="list-disc ml-6">
                <li>This quiz is only for practice purposes.</li>
                <li>
                  You have to submit the quiz within
                  <span className="text-blue-500 mx-1 font-bold">
                    {questionSize * 2} minutes
                  </span>
                  .
                </li>
                <li>You can attempt the quiz any number of times.</li>
                <li>
                  There are
                  <span className="text-blue-500 mx-1 font-bold">
                    {questionSize} questions
                  </span>
                  in this quiz.
                </li>
                <li>
                  Total Marks for this quiz is
                  <span className="text-blue-500 mx-1 font-bold">
                    {questionSize}
                  </span>
                  .
                </li>
                <li>
                  There is a negative marking of
                  <span className="text-blue-500 mx-1 font-bold">
                    0.25 Marks
                  </span>{" "}
                  for each wrong answer.
                </li>
                <li>All questions are of MCQ type.</li>
              </ul>
            </div>

            <hr className="my-4 border-t border-gray-300" />

            <div className="mb-4">
              <h3 className="text-xl font-semibold">Attempting Quiz</h3>
              <ul className="list-disc ml-6">
                <li>Click Start Quiz button to begin the quiz.</li>
                <li>
                  The timer will start the moment you click on the Start Quiz
                  button.
                </li>
                <li>
                  You cannot resume this quiz if interrupted for any reason.
                </li>
                <li>
                  Click on Submit Quiz button upon completion of the quiz.
                </li>
                <li>
                  Results of the test are generated automatically in PDF format.
                </li>
              </ul>
            </div>

            <Button
              className="w-full md:w-auto mt-4 px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
              onClick={() => startQuizHandler(quiz.title, quiz._id)}
            >
              Start Quiz
            </Button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default UserQuizManualPage;
