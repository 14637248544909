import React, { useState } from "react";
import { TiSocialFacebook } from "react-icons/ti";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineYoutube } from "react-icons/ai";
import { addContacts } from "../../actions/contactActions";
import { useDispatch } from "react-redux";
import Swal from "sweetalert";

const Contact = () => {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear the specific error when the user types in the corresponding field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...formErrors };

    // Validate Name
    if (formData.name.trim() === "") {
      newErrors.name = "This field is required";
      valid = false;
    }

    // Validate Mobile Number
    if (formData.mobileNumber.trim() === "") {
      newErrors.mobileNumber = "This field is required";
      valid = false;
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    // Validate Message
    if (formData.message.trim() === "") {
      newErrors.message = "This field is required";
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        // Make the API call to add contact
        const response = await addContacts(dispatch, formData);

        // Handle the response accordingly (e.g., show success message, etc.)
        console.log("Contact added successfully:", response);
        

        // Optionally, you can reset the form after successful submission
        setFormData({
          name: "",
          mobileNumber: "",
          email: "",
          message: "",
        });
      
      } catch (error) {
        // Handle errors from the API call
        console.error("Error adding contact:", error);
      }
    }
  };
  return (
    <>
      <div className="w-full h-full">
        <h1 className="w-full h-20 bg-[#002b50] text-white flex items-center justify-center">
          Contact Us
        </h1>
        <div className="w-3/4 mx-auto my-20">
          <h2>Get intouch</h2>
          <p>
            Have a question or just want to say hi? We'd love to hear from you.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* form */}
            <form onSubmit={handleSubmit}>
              <div className="flex mb-4">
                <div className="mr-4 flex-1">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-2 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 bg-gray-100"
                  />
                  {formErrors.name && (
                    <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
                  )}
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    placeholder="Mobile Number"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    className="w-full px-2 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 bg-gray-100"
                  />
                  {formErrors.mobileNumber && (
                    <p className="text-red-500 text-sm mt-1">{formErrors.mobileNumber}</p>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-2 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 bg-gray-100"
                />
                {formErrors.email && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
                )}
              </div>
              <div className="mb-4">
                <textarea
                  id="message"
                  name="message"
                  placeholder="Enter Your Message"
                  rows="6"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-2 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 bg-gray-100"
                />
                {formErrors.message && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.message}</p>
                )}
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 
                rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
              >
                Send Message
              </button>
            </form>
            <div className="w-full h-96 flex flex-col justify-center p-2 bg-white rounded-md shadow-lg">
              {/* Office Details */}
              <p className="text-gray-600">
                Office: <br />
                Near Sahar Delhi Residency, Kuldeep Vihar, Aligarh- 202001 (UP)
              </p>

              {/* Contact Information */}
              <p>
                Phone: <br /> +919870843098
              </p>
              <p>
                Email: <br />
                reardy2success@gmail.com
              </p>

              {/* Follow Us */}
              <p className="text-gray-600">Follow Us:</p>
              <div className="flex items-center space-x-4">
                <a href="#" className="text-blue-500 hover:text-blue-700">
                  <TiSocialFacebook size={20} />
                </a>
                <a href="#" className="text-blue-500 hover:text-blue-700">
                  <FaTwitter size={20} />
                </a>
                <a href="#" className="text-blue-500 hover:text-blue-700">
                  <FaInstagram size={20} />
                </a>
                <a href="#" className="text-blue-500 hover:text-blue-700">
                  <AiOutlineYoutube size={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
