import React, { useState, useEffect } from "react";
import "../categories/AdminUpdateCategoryPage.css";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import * as resultConstants from "../../../constants/resultConstants";
import FormContainer from "../../../components/FormContainer";
import Sidebar from "../../../components/Sidebar";
import { updateResult, getResultById } from "../../../actions/resultActions";
import { useNavigate } from "react-router-dom";

const AdminUpdateResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const resId = params.resId;
  

const [selectedResult, setSelectedResult] = useState({});

  const [title, setTitle] = useState(
    selectedResult ? selectedResult.title : ""
  );
  const [description, setDescription] = useState(
    selectedResult ? selectedResult.description : ""
  );
  const [resultDate, setResultDate] = useState(
    selectedResult ? selectedResult.resultDate : ""
  );
    const [resultLink, setResultLink] = useState(
        selectedResult ? selectedResult.resultLink : ""
    );
   
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  useEffect(() => {
    getResultById(dispatch, resId, token).then((data) => {
        setSelectedResult(data.payload || {});
        console.log(data.payload);
      setTitle(data.payload ? data.payload.title : "");
        setDescription(data.payload ? data.payload.description : "");   
        setResultDate(data.payload ? data.payload.resultDate : "");
        setResultLink(data.payload ? data.payload.resultLink : "");
       
    });
  }, [dispatch, resId, token]);

  const submitHandler = (e) => {
    e.preventDefault();
    const result ={
        title: title,
        description: description,
        resultDate: resultDate,
        resultLink: resultLink
    }
    updateResult(dispatch, resId, result, token).then((data) => {
      if (data.type === resultConstants.UPDATE_RESULT_SUCCESS) {
        swal("Result Updated!", `${title} succesfully updated`, "success");
      } else {
        swal("Result Not Updated!", `${title} not updated`, "error");
      }
    });
    navigate("/adminResult");
  };
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);

  return (
    <div className="adminUpdateCategoryPage__container">
      <div className="adminUpdateCategoryPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminUpdateCategoryPage__content">
        <FormContainer>
          <h2>Update Result</h2>
          <Form onSubmit={submitHandler}>
                        <Form.Group controlId="title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Result Title"
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                style={{ textAlign: "top" }}
                                as="textarea"
                                rows="5"
                                type="text"
                                placeholder="Enter Category Description"
                                value={description}
                                onChange={(e) => {
                                  setDescription(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="resultDate">
                            <Form.Label>Result Date</Form.Label>
                            <Form.Control
                                className="text"
                                type="date"
                                placeholder="Enter Result Date"
                                value={resultDate}
                                onChange={(e) => {
                                    setResultDate(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="resultLink">
                            <Form.Label>Result Link</Form.Label>
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Result Link"
                                value={resultLink}
                                onChange={(e) => {
                                    setResultLink(e.target.value);
                                }}
                            ></Form.Control>    
                        </Form.Group>
                            
                        
                        
                        



                        <Button
                            className="my-2 adminAddCategoryPage__content--button text-white"
                            style={{ backgroundColor: "#44b131" }}
                            type="submit"
                            variant=""
                        >
                            Update
                        </Button>
                    
          </Form>
        </FormContainer>
      </div>
    </div>
  );
};

export default AdminUpdateResult;
