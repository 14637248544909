import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const loginReducer = useSelector((state) => state.loginReducer);

  useEffect(() => {
    if (localStorage.getItem("jwtToken")) {
      if (loginReducer.user.roles === "admin") {
        navigate("/adminProfile");
      }
    }
  }, [navigate]);

  return (
    <header>
      <Navbar
        style={{ background: "#002b50" }}
        variant="dark"
        expand="lg"
        collapseOnSelect
      >
        <Container>
          {/* Add your logo here */}
          <Navbar.Brand style={{ fontSize: "xx-large" }} className="mx-auto">
            <LinkContainer to="/">
              <img
                src="R2SMain.png"
                alt="Your"
                height="60"
                className="d-inline-block align-center w-[100%] h-[90px]"
              />
            </LinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <LinkContainer to="/">
                <Nav.Link as={Link} to="/" className="mr-4">
                  Home
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/quizzes">
                <Nav.Link as={Link} to="/test-series" className="mr-4">
                  Test Series
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/jobs">
                <Nav.Link as={Link} to="/jobs" className="mr-4">
                  Jobs
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/admit-card">
                <Nav.Link as={Link} to="/admit-card" className="mr-4">
                  Admit Card
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/job-results">
                <Nav.Link as={Link} to="/job-result" className="mr-4">
                  Result
                </Nav.Link>
              </LinkContainer>
              <Nav.Link
                href="https://ndl.iitkgp.ac.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Digital Library
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
