import React, { useState, useEffect } from "react";
import "../categories/AdminAddCategoryPage.css";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as categoriesConstants from "../../../constants/jobsConstants";
import FormContainer from "../../../components/FormContainer";
import Sidebar from "../../../components/Sidebar";
import {
    addJob,
    // fetchCategories,
} from "../../../actions/jobsActions";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const AdminAddJobs = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [examDate, setExamDate] = useState("");
    const [generalFee, setGeneralFee] = useState("");
    const [scFee, setScFee] = useState("");
    const [femaleFee, setFemaleFee] = useState("");
    const [ageLimit, setAgeLimit] = useState("");
    const [totalVacancy, setTotalVacancy] = useState("");
    const [generalVacancy, setGeneralVacancy] = useState("");
    const [scVacancy, setScVacancy] = useState("");
    const [stVacancy, setStVacancy] = useState("");
    const [obcVacancy, setObcVacancy] = useState("");
    const [ewsVacancy, setEwsVacancy] = useState("");
    const [applyLink, setApplyLink] = useState("");
    const [syllabusLink, setSyllabusLink] = useState("");
    const [officialLink, setOfficialLink] = useState("");



    const token = JSON.parse(localStorage.getItem("jwtToken"));
    

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitHandler = (e) => {
        e.preventDefault();
        if (title === "") {
            swal("Category Not Added!", "Please Enter Title", "error");
        } else if (description === "") {
            swal("Category Not Added!", "Please Enter Description", "error");
        } else {
            const jobData = {
                title: title,
                description: description,
                importantDates: {
                    start: start,
                    end :end,
                    examDate: examDate,
                  },
                feeDetails: {
                    general: generalFee,
                    sc: scFee,
                    female: femaleFee,
                },
                ageLimit: ageLimit,
                post: {
                    totalVacancy: totalVacancy,
                    general: generalVacancy,
                    sc: scVacancy,
                    st: stVacancy,
                    obc: obcVacancy,
                    ews: ewsVacancy,
                },
                importantLinks: {
                    applyLink: applyLink,
                    syllabusLink: syllabusLink,
                    officialLink: officialLink,
                },
            }
            console.log(jobData);
            addJob(dispatch, jobData, token).then((data) => {

                if (data.type === categoriesConstants.ADD_JOBS_SUCCESS) {
                    swal("Job Added!", `${title} succesfully added`, "success");
                } else {
                    swal("Job Not Added!", `${title} not added`, "error");
                }
                navigate("/adminJobs");
            });
        }
    };

    useEffect(() => {
        if (!localStorage.getItem("jwtToken")) navigate("/");
      }, []);

    return (
        <div className="adminAddCategoryPage__container">
            <div className="adminAddCategoryPage__sidebar">
                <Sidebar />
            </div>
            <div className="adminAddCategoryPage__content">
                <FormContainer>
                    <h2 className="w-full text-2xl text-center">Add Jobs</h2>
                    <Form onSubmit={submitHandler}>
                        <Form.Group controlId="title">
                            {/* <Form.Label>Title</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Job Title"
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>

                        <Form.Group controlId="description">
                            {/* <Form.Label>Description</Form.Label> */}
                            <Form.Control
                                className="text"
                                style={{ textAlign: "top" }}
                                as="textarea"
                                rows="5"
                                type="text"
                                placeholder="Enter Category Description"
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <div className="flex gap-2 my-2">
                            
                        <Form.Group controlId="start">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                className="text"
                                type="date"
                                placeholder="Start Date"
                                value={start}
                                onChange={(e) => {
                                    setStart(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="end">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                className="text"
                                type="date"
                                placeholder="Last Date"
                                value={end}
                                onChange={(e) => {
                                    setEnd(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        
                        </div>
                        <Form.Group controlId="examDate">
                            <Form.Label>Exam Date</Form.Label>
                            <Form.Control
                                className="text"
                                type="date"
                                placeholder="Exam Date"
                                value={examDate}
                                onChange={(e) => {
                                    setExamDate(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <p>Enter Fee Details</p>
                        <div className="flex gap-2 my-2">
                        <Form.Group controlId="generalFee">
                            {/* <Form.Label>General Fee</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="General Fee"
                                value={generalFee}
                                onChange={(e) => {
                                    setGeneralFee(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="scFee">
                            {/* <Form.Label>SC Fee</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="SC Fee"
                                value={scFee}
                                onChange={(e) => {
                                    setScFee(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="femaleFee">
                            {/* <Form.Label>Female Fee</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Female Fee"
                                value={femaleFee}
                                onChange={(e) => {
                                    setFemaleFee(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        </div>
                        <Form.Group controlId="ageLimit">
                            {/* <Form.Label>Age Limit</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Age Limit"
                                value={ageLimit}
                                onChange={(e) => {
                                    setAgeLimit(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <p>Vacancy Details</p>
                        <Form.Group controlId="totalVacancy">
                            {/* <Form.Label>Total Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Total Vacancy"
                                value={totalVacancy}
                                onChange={(e) => {
                                    setTotalVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="generalVacancy">
                            {/* <Form.Label>General Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter General Vacancy"
                                value={generalVacancy}
                                onChange={(e) => {
                                    setGeneralVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="scVacancy">
                            {/* <Form.Label>SC Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter SC Vacancy"
                                value={scVacancy}
                                onChange={(e) => {
                                    setScVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="stVacancy">
                            {/* <Form.Label>ST Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter ST Vacancy"
                                value={stVacancy}
                                onChange={(e) => {
                                    setStVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="obcVacancy">
                            {/* <Form.Label>OBC Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter OBC Vacancy"
                                value={obcVacancy}
                                onChange={(e) => {
                                    setObcVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="ewsVacancy">
                            {/* <Form.Label>EWS Vacancy</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter EWS Vacancy"
                                value={ewsVacancy}
                                onChange={(e) => {
                                    setEwsVacancy(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <p>Important Links</p>
                        <Form.Group controlId="applyLink">
                            {/* <Form.Label>Apply Link</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Apply Link"
                                value={applyLink}
                                onChange={(e) => {
                                    setApplyLink(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="syllabusLink">
                            {/* <Form.Label>Syllabus Link</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Syllabus Link"
                                value={syllabusLink}
                                onChange={(e) => {
                                    setSyllabusLink(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group controlId="officialLink">
                            {/* <Form.Label>Official Link</Form.Label> */}
                            <Form.Control
                                className="text"
                                type="text"
                                placeholder="Enter Official Link"
                                value={officialLink}
                                onChange={(e) => {
                                    setOfficialLink(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                        
                        



                        <Button
                            className="my-2 adminAddCategoryPage__content--button text-white"
                            style={{ backgroundColor: "#44b131" }}
                            type="submit"
                            variant=""
                        >
                            Add
                        </Button>
                    </Form>
                </FormContainer>
            </div>
        </div>
    );
};

export default AdminAddJobs;
