import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import "./AdminUpdateQuiz.css";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../../components/Sidebar";
import FormContainer from "../../../components/FormContainer";
import * as quizzesConstants from "../../../constants/quizzesConstants";
import { fetchCategories } from "../../../actions/categoriesActions";
import "./AdminUpdateQuiz.css";
import { fetchQuizzes, updateQuiz, fetchQuizById } from "../../../actions/quizzesActions";

const AdminUpdateQuiz = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const quizId = params.quizId;
  

  console.log("quizId", quizId);

  // const quizReducer = useSelector((state) => state.quizzesReducer.quizzes);
  // const selectedQuiz = quizReducer.find((quiz) => quiz._id === quizId);
  const [selectedQuiz, setSelectedQuiz] = useState({});



  const [title, setTitle] = useState(selectedQuiz.title);
  const [description, setDescription] = useState(selectedQuiz.description);
  const [maxMarks, setMaxMarks] = useState(selectedQuiz.marks);
  const [numberOfQuestions, setNumberOfQuestions] = useState(
    selectedQuiz.totalQuestions
  );
  const [isActive, setIsActive] = useState(selectedQuiz.isActive);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  // const categoriesReducer = useSelector((state) => state.categoriesReducer);
  const [categories, setCategories] = useState([]);

  const onClickPublishedHandler = () => {
    setIsActive(!isActive);
  };

  const onSelectCategoryHandler = (e) => {
    setSelectedCategoryId(e.target.value);
  };

  const token = JSON.parse(localStorage.getItem("jwtToken"));

  const submitHandler = (e) => {
    e.preventDefault();
    if (selectedCategoryId !== null && selectedCategoryId !== "n/a") {
      const quiz = {
        quizId: quizId,
        title: title,
        description: description,
        isActive: isActive,
        category: selectedCategoryId,
        // marks: maxMarks,
        // totalQuestions: numberOfQuestions,
      };
      updateQuiz(dispatch, quiz).then((data) => {
        if (data.type === quizzesConstants.UPDATE_QUIZ_SUCCESS) {
          swal("Quiz Updated!", `${quiz.title} succesfully updated`, "success");
          fetchQuizzes(dispatch);
        } else {
          swal("Quiz Not Updated!", `${quiz.title} not updated`, "error");
        }
      });
    } else {
      alert("Select valid category!");
    }
  };
 

  useEffect(() => {
    if (categories.length === 0) {
      fetchCategories(dispatch).then((data) => {
        setCategories(data.payload);
      });
    }
  }, [categories]);

  useEffect(() => {
    fetchQuizById(dispatch, quizId).then((data) => {
      setSelectedQuiz(data.payload);
      setTitle(data.payload.title);
      setDescription(data.payload.description);
      setIsActive(data.payload.isActive);
      setSelectedCategoryId(data.payload.category);
      // setMaxMarks(data.payload.marks);
      // setNumberOfQuestions(data.payload.totalQuestions);
    }
    );
  }, [quizId]);


  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) navigate("/");
  }, []);
  return (
    <div className="adminUpdateQuizPage__container">
      <div className="adminUpdateQuizPage__sidebar">
        <Sidebar />
      </div>
      <div className="adminUpdateQuizPage__content">
        <FormContainer>
          <h2>Update Test</h2>
          <Form onSubmit={submitHandler}>
            <Form.Group className="my-1" controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Quiz Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="my-1" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                style={{ textAlign: "top" }}
                as="textarea"
                rows="3"
                type="text"
                placeholder="Enter Quiz Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>

            {/* <Form.Group className="my-1" controlId="maxMarks">
              <Form.Label>Maximum Marks</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Maximum Marks"
                value={maxMarks}
                onChange={(e) => {
                  setMaxMarks(e.target.value);
                }}
              ></Form.Control>
            </Form.Group> */}

            {/* <Form.Group className="my-1" controlId="numberOfQuestions">
              <Form.Label>Number of Questions</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Number of Questions"
                value={numberOfQuestions}
                onChange={(e) => {
                  setNumberOfQuestions(e.target.value);
                }}
              ></Form.Control>
            </Form.Group> */}

            <Form.Check
              style={{ borderColor: "rgb(68 177 49)" }}
              className="my-1"
              type="switch"
              id="publish-switch"
              label="Publish Quiz"
              onChange={onClickPublishedHandler}
              checked={isActive}
            />

            <div className="my-1">
              <label htmlFor="category-select">Choose a Category:</label>
              <Form.Select
                aria-label="Choose Category"
                id="category-select"
                onChange={onSelectCategoryHandler}
              >
                <option value="n/a">Choose Category</option>
                {categories ? (
                  categories.map((cat, index) => (
                    <option key={index} value={cat.catId}>
                      {cat.title}
                    </option>
                  ))
                ) : (
                  <option value="">Choose one from below</option>
                )}
                {/* <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option> */}
              </Form.Select>
            </div>
            <Button
              className="my-5 adminUpdateQuizPage__content--button"
              type="submit"
              variant="primary"
            >
              Update
            </Button>
          </Form>
        </FormContainer>
      </div>
    </div>
  );
};

export default AdminUpdateQuiz;
