import React, {useEffect, useState} from "react";
import TableComponent from "../../components/TableComponent";
import { fetchAdmitCard } from "../../actions/admitCardActions";
import { useDispatch } from "react-redux";

const AdmitCard = () => {

  const [admitData, setAdmitData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAdmitCard(dispatch).then((data) => {
      setAdmitData(data.payload);
    });
  }, []);
  console.log(admitData);
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  };

  const columns = [
    // { key: "_id", label: "ID" },
    { key: "title", label: "Job Title" },
    { key: "description", label: "Description" },
    { key: "createdAt", label: "Post Date", format: formatDate },
  ];

  return (
    <TableComponent
      data={admitData}
      heading="Admit Card"
      columns={columns}
      name="Download"
      link="/admit-card"
    />
  );
};

export default AdmitCard;
