import axios from "axios";
const apiBaseUrl = process.env.REACT_APP_SERVER_URL

const fetchResults = async (token) => {
    try {
        // const config = {
        //   headers: { Authorization: `Bearer ${token}` },
        // };
        const { data } = await axios.get(`${apiBaseUrl}/api/result/`);
        return data;
    } catch (error) {
        console.error(
        "resultService:fetchResults() Error: ",
        error.response.statusText
        );
        return error.response.statusText;
    }
    }

const addResult = async (result, token) => {
    try {
        // const config = {
        //   headers: { Authorization: `Bearer ${token}` },
        // };
        console.log(result);
        const { data } = await axios.post(`${apiBaseUrl}/api/result`, result);
        return { data: data, isAdded: true, error: null };
    } catch (error) {
        console.error(
        "resultService:addResult() Error: ",
        error.response.statusText
        );
        return { data: null, isAdded: false, error: error.response.statusText };
    }
    }

const deleteResult = async (resultId, token) => {
    try {
        
        const { data } = await axios.delete(`${apiBaseUrl}/api/result/${resultId}/`);
        return {
        isDeleted: true,
        error: null,
        };
    } catch (error) {
        console.error(
        "resultService:deleteResult()  Error: ",
        error.response.statusText
        );
        return {
        isDeleted: false,
        error: error.response.statusText,
        };
    }
    }

const updateResult = async (result,resId, token) => {
    try {
        const config = {
        headers: { Authorization: `Bearer ${token}` },
        };
        const { data } = await axios.put(`${apiBaseUrl}/api/result/${resId}`, result, config);
        return { data: data, isUpdated: true, error: null };
    } catch (error) {
        console.error(
        "resultService:updateResult() Error: ",
        error.response.statusText
        );
        return { data: null, isUpdated: false, error: error.response.statusText };
    }
    }

    const getResultById = async (resultId) => {
        try {
            const { data } = await axios.get(`${apiBaseUrl}/api/result/${resultId}`);
            return data;
        } catch (error) {
            console.error(
            "resultService:getResultById() Error: ",
            error.response.statusText
            );
            return error.response.statusText;
        }
        }

export default {
    fetchResults,
    addResult,
    deleteResult,
    updateResult,
    getResultById
};