import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchAdmitById } from "../../actions/admitCardActions";
import { useDispatch, useSelector } from "react-redux";

const AdmitCardDetail = () => {
  const params = useParams();
  const admitId = params.admitId;
  console.log(admitId);
  const [selectedAdmit, setSelectedAdmit] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAdmitById(dispatch, admitId).then((data) => {
      setSelectedAdmit(data.payload || {});
    });
  }, [dispatch, admitId]);
  console.log(selectedAdmit);


  return (
    <>
      <table className="w-620 mx-auto my-5 border-collapse border-3">
        <tbody>
          <tr>
            <td colSpan="14" className="text-center border p-2">
              <h2 className="text-blue-600">
                <b>{selectedAdmit?.title}</b>
              </h2>
              {/* <h2 className="text-orange-400">
                <b>UP Police Computer Operator & Programmer Recruitment 2023</b>
              </h2> */}
              <h2 className="text-blue-600">
                <b>{selectedAdmit?.description}</b>
              </h2>
              <h2 className="text-red-400">
                <b>
                  <a
                    title="Sarkari Result 2024"
                    href="/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <span className="text-red-500">WWW.SARKARIRESULT.COM</span> */}
                    <div className="logo-holder logo-4">
                      <a href="/">
                        <h3>
                          Sarkari{" "}
                          <span style={{ color: "black" }}> Exams </span>Hub
                        </h3>
                        {/* <p>ipsum dolor sit</p> */}
                      </a>
                    </div>
                  </a>
                </b>
              </h2>
            </td>
          </tr>
          <tr>
            <td colSpan="8" className="border p-2">
              <h2 className="text-center text-orange-400">
                <b>Important Dates</b>
              </h2>
              <ul>
                <li>
                  Admit Card Date :{" "}
                  <span className="text-red-400">
                    {selectedAdmit.admitCardDate}
                  </span>
                </li>
                <li>
                 Exam Date :{" "}
                  <span className="text-red-400">
                    {selectedAdmit.examDate}
                  </span>
                </li>
                
                {/* <li>
                  Admit Card Available: <b>Before Exam</b>
                </li> */}
              </ul>
            </td>
            <td colSpan="6" className="border p-2">
              <h2 className="text-center text-orange-400">
                <b>Result Link</b>
              </h2>
              <ul>
                <li>
                  Link :{" "}
                  <b>
                    <a href={selectedAdmit.admitCardLink}>
                        Click here
                    </a>
                  </b>
                </li>
                </ul>
            </td>
          </tr>
         

          {/* <tr className="border">
            <td valign="top" width="125" className="border p-2">
              <p className="text-center">Computer Operator</p>
            </td>
            <td colSpan="5" valign="top" width="159" className="border p-2">
              <p className="text-center">930</p>
            </td>
            <td colSpan="8" valign="top" width="336" className="border p-2">
              <ul>
                <li>
                  10+2 Intermediate Exam with PCM Subjects and O Level Exam
                  Passed OR Diploma in Computer Application / IT / Electronics
                  Engineering.
                </li>
                <li>More Eligibility Details Read the Notification.</li>
              </ul>
            </td>
          </tr> */}
          {/* <tr className="border">
            <td valign="top" width="125" className="border p-2">
              <p className="text-center">Programmer Grade II</p>
            </td>
            <td colSpan="5" valign="top" width="159" className="border p-2">
              <p className="text-center">55</p>
            </td>
            <td colSpan="8" valign="top" className="border p-2">
              <ul>
                <li>
                  Bachelor Degree in Any Stream with NIELIT "A" Level Exam OR
                  Bachelor Degree in Science B.Sc in Computer Science / IT /
                  Electronics with PGDCA.
                </li>
                <li>More Eligibility Details Read the Notification.</li>
              </ul>
            </td>
          </tr> */}
          
          {/* <tr className="border">
            <td colSpan="3" valign="top" width="186" className="border p-2">
              <p className="text-center">UP Police Programmer Grade II</p>
            </td>
            <td colSpan="2" valign="top" width="95" className="border p-2">
              <p className="text-center">24</p>
            </td>
            <td colSpan="4" valign="top" width="77" className="border p-2">
              <p className="text-center">05</p>
            </td>
            <td valign="top" width="76" className="border p-2">
              <p className="text-center">14</p>
            </td>
            <td colSpan="2" valign="top" width="55" className="border p-2">
              <p className="text-center">11</p>
            </td>
            <td valign="top" width="59" className="border p-2">
              <p className="text-center">01</p>
            </td>
            <td valign="top" width="73" className="border p-2">
              <p className="text-center">55</p>
            </td>
          </tr> */}
          {/* <tr className="border">
            <td colSpan="14" valign="top" width="620" className="border p-2">
              <h2 className="text-center text-orange-400">
                How to Fill UP Police Computer Operator / Programmer Recruitment
                Online Form 2024
              </h2>
              <ul>
                <li>
                  UP Police Computer Operator & Programmer Grade II Recruitment
                  for Various Post Candidate Can Apply Between{" "}
                  <b>07/01/2024 to 28/01/2024.</b>
                </li>
                <li>
                  Candidate Read the Notification Before Apply the Recruitment
                  Application Form in Computer Operator and Programmer Grade 2
                  Recruitment 2024.
                </li>
                <li>
                  Kindly Check and Collect the All Document - Eligibility, ID
                  Proof, Address Details, Basic Details.
                </li>
                <li>
                  Kindly Ready Scan Document Related to Recruitment Form -
                  Photo, Sign, ID Proof, Etc.
                </li>
                <li>
                  Before Submit the Application Form Must Check the Preview and
                  All Column Carefully.
                </li>
                <li>
                  If Candidate Required to Paying the Application Fee Must
                  Submit. If You have Not the Required Application Fees Your
                  Form is Not Completed.
                </li>
                <li>Take A Print Out of Final Submitted Form.</li>
              </ul>
            </td>
          </tr> */}
          
          
    
        </tbody>
      </table>
    </>
  );
};

export default AdmitCardDetail;
