import React from "react";
import { useNavigate } from "react-router-dom";
import { fetchQuestionsByQuiz } from "../actions/questionsActions";

const Card = ({ quizzes }) => {
  const navigate = useNavigate();
  console.log(quizzes);

  return (
    <>
      {quizzes.map((quiz, index) => (
        <div
          key={index}
          className="relative w-[240px] h-[323px] bg-gradient-to-b from-orange-200 via-white shadow-xl to-white flex flex-col items-start px-2 py-2 border border-orange-200 rounded-lg"
        >
          <h2 className="text-base text-gray-950 font-medium">{quiz.title}</h2>
          <p className="w-full flex justify-start items-center font-semibold text-xs text-gray-600 py-2">
            Category:
            <span className="pl-2 text-gray-800 text-sm">{quiz.category}</span>
          </p>
          <ul
            className="w-full h-auto flex flex-col items-start gap-2 text-sm"
            style={{ listStyleType: "disc" }}
          >
            <li>{quiz.totalQuestions} Questions</li>
            <li>{quiz.marks} Marks</li>
            <li>{quiz.description}</li>
          </ul>
          <div className="w-full flex items-center justify-center">
            <button
              onClick={() => navigate(`/quizManual?quizId=${quiz._id}`)}
              className="absolute w-auto bottom-0 bg-orange-400 text-white my-2 px-4 py-2 rounded-md"
            >
              View Test Series
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default Card;
