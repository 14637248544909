import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarUser from "../../components/SidebarUser";
import "./UserQuizzesPage.css";
import { fetchQuizzes } from "../../actions/quizzesActions";
import { Card, Col, Row } from "react-bootstrap";
// import Card from "../../components/Card";

const mockQuizzes = [
  {
    quizId: "65b3789894d8b722b7a42f22",
    title: "Mock Quiz 1",
    category: { catId: 1, title: "Category 1" },
    description: "This is a mock quiz for testing.",
    totalQuestions: 10,
  },
  // Add more mock quizzes as needed
];

const UserQuizzesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const catId = urlParams.get("catId");
  const token = JSON.parse(localStorage.getItem("jwtToken"));

  const quizzesReducer = useSelector((state) => state.quizzesReducer);
  const [quizzes, setQuizzes] = useState(quizzesReducer.quizzes);

  useEffect(() => {
    // if (quizzes.length !== 0) {
    fetchQuizzes(dispatch, token).then((data) => {
      setQuizzes(data.payload);
    });
    // }
    // setQuizzes(mockQuizzes);
  }, []);

  // useEffect(() => {
  //   if (!localStorage.getItem("jwtToken")) navigate("/");
  // }, []);

  return (
    <div className="userQuizzesPage__container">
      <div className="userQuizzesPage__sidebar">{/* <SidebarUser /> */}</div>

      <div className="userQuizzesPage__content">
        {quizzes ? (
          <Row>
            {quizzes.map((q, index) => {
              if ((catId && catId == q.category.catId) || catId == null)
                return (
                  <Col
                    key={index}
                    xl={3}
                    lg={4}
                    md={6}
                    sm={6}
                    xs={12}
                    style={{}}
                  >
                    <Card
                      bg="light"
                      text="dark"
                      style={{
                        width: "100%",
                        height: "95%",
                        padding: "5px",
                        margin: "auto",
                        marginTop: "5px",
                        minWidth: "0px",
                        wordWrap: "break-word",
                      }}
                      className="mb-2"
                    >
                      <Card.Body>
                        <Card.Title>{q.title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                          {q.category}
                        </Card.Subtitle>
                        <Card.Text>{q.description}</Card.Text>
                        <div className="userQuizzesPage__content--ButtonsList">
                          <div
                            className="userQuizzesPage__content--Button"
                            onClick={() =>
                              navigate(`/quizManual?quizId=${q._id}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {`Start`}
                          </div>

                          <div
                            className="userQuizzesPage__content--Button"
                            onClick={() => console.log("View")}
                            style={{ color: "black", backgroundColor: "white" }}
                          >{`${q.questions.length * 2} Minutes`}</div>

                          <div
                            className="userQuizzesPage__content--Button"
                            onClick={() => console.log("View")}
                            style={{ color: "black", backgroundColor: "white"}}
                          >{`${q.questions.length} Questions`}</div>

                          <div
                            className="userQuizzesPage__content--Button"
                            onClick={() => console.log("View")}
                            style={{ color: "black", backgroundColor: "white" }}
                          >{`Marks : ${q.questions.length}`}</div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
            })}
          </Row>
        ) : (
          <p>No Quizzes Available</p>
        )}
      </div>
    </div>
    // <div className="w-3/4 mx-auto pl-4 md:pl-0">
    //   <h1>Popular Test Series</h1>
    //   {/* all cards */}
    //   <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-40 md:grid-cols-4 mb-5 mx-auto">
    //     <Card />
    //     <Card />
    //     <Card />
    //     <Card />
    //     <Card />
    //     <Card />
    //     <Card />
    //     <Card />
    //   </div>
    //   <button className="hover:bg-blue-400 hover:text-white  my-4 mx-auto px-4 py-2 border-2 border-blue-400">
    //     Explore All Test Series
    //   </button>
    // </div>
  );
};

export default UserQuizzesPage;
